<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="uuid" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.app里边的sessionID')}}<template slot="title">{{$t('设备检测数据集合.app里边的sessionID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.uuid" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.app里边的sessionID')" />
      </a-form-model-item>
      <a-form-model-item prop="serialNumber" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.板子号')}}<template slot="title">{{$t('设备检测数据集合.板子号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.serialNumber" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.板子号')" />
      </a-form-model-item>
      <a-form-model-item prop="sequenceNumber" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.序列号(1234递增,类型为CGM)')}}<template slot="title">{{$t('设备检测数据集合.序列号(1234递增,类型为CGM)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sequenceNumber" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.序列号(1234递增,类型为CGM)')" />
      </a-form-model-item>
      <a-form-model-item prop="recordedAt" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.0时区时间')}}<template slot="title">{{$t('设备检测数据集合.0时区时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.recordedAt" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="utcOffset" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.时区 28800代表的就是中国时区')}}<template slot="title">{{$t('设备检测数据集合.时区 28800代表的就是中国时区')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.utcOffset"  :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.时区 28800代表的就是中国时区')" />
      </a-form-model-item>
      <a-form-model-item prop="recordedAtLocal" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.本地时间')}}<template slot="title">{{$t('设备检测数据集合.本地时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.recordedAtLocal" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="rawValue" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.电流值(设备里传的)')}}<template slot="title">{{$t('设备检测数据集合.电流值(设备里传的)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.rawValue"  :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.电流值(设备里传的)')" />
      </a-form-model-item>
      <a-form-model-item prop="glucoseConcentration" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.血糖值(算法算出来的,前端)')}}<template slot="title">{{$t('设备检测数据集合.血糖值(算法算出来的,前端)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.glucoseConcentration"  :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.血糖值(算法算出来的,前端)')" />
      </a-form-model-item>
      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.类型 CGM(来自于传感器)  SMBG(产品还可以配合,固定的止血仪) YSI')}}<template slot="title">{{$t('设备检测数据集合.类型 CGM(来自于传感器)  SMBG(产品还可以配合,固定的止血仪) YSI')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="sensorStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.传感器状态 warmup (前端处理,前60秒都是待启动) goods(正常)  shutdown()')}}<template slot="title">{{$t('设备检测数据集合.传感器状态 warmup (前端处理,前60秒都是待启动) goods(正常)  shutdown()')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备检测数据集合.备注')}}<template slot="title">{{$t('设备检测数据集合.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('设备检测数据集合.备注')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBloodSugarDatas, addBloodSugarDatas, updateBloodSugarDatas } from '@/api/bloodSugarDatas/bloodSugarDatas'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        uuid: null,

        serialNumber: null,

        sequenceNumber: null,

        recordedAt: null,

        utcOffset: null,

        recordedAtLocal: null,

        rawValue: null,

        glucoseConcentration: null,

        type: null,

        sensorStatus: '0',

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        uuid: [
          { required: true, message: this.$t('设备检测数据集合.app里边的sessionID')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        serialNumber: [
          { required: true, message: this.$t('设备检测数据集合.板子号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        sequenceNumber: [
          { required: true, message: this.$t('设备检测数据集合.序列号(1234递增,类型为CGM)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        uuid: null,
        serialNumber: null,
        sequenceNumber: null,
        recordedAt: null,
        utcOffset: null,
        recordedAtLocal: null,
        rawValue: null,
        glucoseConcentration: null,
        type: null,
        sensorStatus: '0',
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBloodSugarDatas({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateBloodSugarDatas(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addBloodSugarDatas(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
