import request from '@/utils/request'


// 查询设备检测数据集合列表
export function listBloodSugarDatas(query) {
  return request({
    url: '/bloodSugarDatas/bloodSugarDatas/list',
    method: 'get',
    params: query
  })
}

// 查询设备检测数据集合分页
export function pageBloodSugarDatas(query) {
  return request({
    url: '/bloodSugarDatas/bloodSugarDatas/page',
    method: 'get',
    params: query
  })
}

// 查询设备检测数据集合详细
export function getBloodSugarDatas(data) {
  return request({
    url: '/bloodSugarDatas/bloodSugarDatas/detail',
    method: 'get',
    params: data
  })
}

// 新增设备检测数据集合
export function addBloodSugarDatas(data) {
  return request({
    url: '/bloodSugarDatas/bloodSugarDatas/add',
    method: 'post',
    data: data
  })
}

// 修改设备检测数据集合
export function updateBloodSugarDatas(data) {
  return request({
    url: '/bloodSugarDatas/bloodSugarDatas/edit',
    method: 'post',
    data: data
  })
}

// 删除设备检测数据集合
export function delBloodSugarDatas(data) {
  return request({
    url: '/bloodSugarDatas/bloodSugarDatas/delete',
    method: 'post',
    data: data
  })
}
